$.fn.bootstrapPasswordMeter = function(options) {
    var settings = $.extend({
        minPasswordLength: 8,
        level0ClassName: 'progress-bar-danger',
        //level0Description: 'Weak',
        level0Description: '',
        level1ClassName: 'progress-bar-danger',
        //level1Description: 'Not great',
        level1Description: '',
        level2ClassName: 'progress-bar-warning',
        //level2Description: 'Better',
        level2Description: '',
        level3ClassName: 'progress-bar-success',
        //level3Description: 'Strong',
        level3Description: '',
        level4ClassName: 'progress-bar-success',
        //level4Description: 'Very strong',
        level4Description: '',
        parentContainerClass: '.form-group'
    }, options || {});

    $(this).on("keyup", function() {
        var progressBar = $('.progress');
        var progressBarWidth = 0;
        var progressBarDescription = '';
        var submit = $('#set_password_submit');
        var score = 0;

        if ($(this).val().length >= settings.minPasswordLength) {
            score++;
        }

        // at least 1 digit in password
        if ($(this).val().match(/\d/)) {
            score++;
        }

        // at least 1 capital & lower letter in password
        if ($(this).val().match(/[A-Z]/) && $(this).val().match(/[a-z]/)) {
            score++;
        }

        // at least 1 special character in password {
        if ($(this).val().match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/) ) {
            score++;
        }

        progressBar.removeClass(settings.level0ClassName)
            .removeClass(settings.level1ClassName)
            .removeClass(settings.level2ClassName)
            .removeClass(settings.level3ClassName)
            .removeClass(settings.level4ClassName);

        switch (score) {
            case 0:
                progressBarWidth = 25;
                progressBar.addClass(settings.level0ClassName);
                progressBarDescription = settings.level0Description;
                break;
            case 1:
                progressBarWidth = 25;
                progressBar.addClass(settings.level1ClassName);
                progressBarDescription = settings.level1Description;
                break;
            case 2:
                progressBarWidth = 50;
                progressBar.addClass(settings.level2ClassName);
                progressBarDescription = settings.level2Description;
                break;
            case 3:
                progressBarWidth = 75;
                progressBar.addClass(settings.level3ClassName);
                progressBarDescription = settings.level3Description;
                break;
            case 4:
                progressBarWidth = 100;
                progressBar.addClass(settings.level4ClassName);
                progressBarDescription = settings.level4Description;
                break;
        }

        if (score < 3) {
            submit.prop('disabled', true);
            submit.addClass('disabled');
        } else {
            submit.prop('disabled', false);
            submit.removeClass('disabled');
        }

        progressBar.css('width', progressBarWidth + '%');
        progressBar.text(progressBarDescription);
    });
};
$('#fos_user_change_password_form_plainPassword_first').bootstrapPasswordMeter({minPasswordLength:8});
$('#fos_user_resetting_form_plainPassword_first').bootstrapPasswordMeter({minPasswordLength:8});
