!function(e){"$:nomunge";var n,t,a,r,o=1,i=this,s=!1,c="postMessage",u="addEventListener",v=i[c]&&!0;e[c]=function(n,t,a){t&&(n="string"==typeof n?n:e.param(n),a=a||parent,v?a[c](n,t.replace(/([^:]+:\/\/[^\/]+).*/,"$1")):t&&(a.location=t.replace(/#.*$/,"")+"#"+ +new Date+o++ +"&"+n))},e.receiveMessage=r=function(o,c,l){v?(o&&(a&&r(),a=function(n){return"string"==typeof c&&n.origin!==c||e.isFunction(c)&&c(n.origin)===s?s:void o(n)}),i[u]?i[o?u:"removeEventListener"]("message",a,s):i[o?"attachEvent":"detachEvent"]("onmessage",a)):(n&&clearInterval(n),n=null,o&&(l="number"==typeof c?c:"number"==typeof l?l:100,n=setInterval(function(){var e=document.location.hash,n=/^#?\d+&/;e!==t&&n.test(e)&&(t=e,o({data:e.replace(n,"")}))},l)))}}(jQuery);

var Isign = Isign || {};

(function($) {
    
    Isign.PostMessage = {
        init: function(){
            var _this = this;

            $.receiveMessage(function(e){
                _this.handleMessage(e.data);
            }, _this.getFrameDomain());
        },

        getFrameDomain: function(){
            var parser = document.createElement('a');
            parser.href = $('iframe#isign-gateway').attr('src');

            return parser.protocol + '//' + parser.hostname;
        },
        
        handleMessage: function(stringMessage){
            message = $.parseJSON(stringMessage);

            switch(message.action){
                case 'adjustHeight':
                        if(typeof Isign.adjustHeight === 'function'){
                            Isign.adjustHeight(message.height + 50);
                        }
                    break;
                case 'onSignSuccess':
                        if(typeof Isign.onSignSuccess === 'function'){
                            Isign.onSignSuccess();
                        }
                    break;
                case 'onSignError':
                        if(typeof Isign.onSignError === 'function'){
                            Isign.onSignError(message.errors);
                        }
                    break;
                case 'onSequentialSigningComplete':
                    if(typeof Isign.onSequentialSigningComplete === 'function'){
                        Isign.onSequentialSigningComplete();
                    }
                    break;
            }
        }
    },
    
    Isign.adjustHeight = function(height){
        $('iframe#isign-gateway').height(height + 50);
    }
        
})(jQuery);

$(document).ready(function(){
    Isign.PostMessage.init();
});