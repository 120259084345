$(function() {
    var $testForm = $('.js-test-wizard'),
        validatorOptions = {
            //errorElement: 'em',
            errorLabelContainer: '.js-course-test-error-box',
            submitHandler: function (form) {
                form.submit();
            }
        };
    $.validator.messages.required = messages.test_answer_is_required;

    $testForm.validate(validatorOptions);

    if ($testForm.find('fieldset').length > 1) {
        $testForm.formToWizard({
            submitButton: 'wizardSubmit',
            nextBtnClass: 'btn btn-primary next',
            prevBtnClass: 'btn btn-default prev',
            buttonTag: 'button',
            showStepNo: false,
            nextBtnName: messages.test_next + ' &gt;',
            prevBtnName: '&lt; ' + messages.test_previous,
            validateBeforeNext: function (form, step) {
                var stepIsValid = true;
                var validator = form.validate(validatorOptions);
                $(':input', step).each(function () {
                    var xy = validator.element(this);
                    stepIsValid = stepIsValid && (typeof xy === 'undefined' || xy);
                });
                if (stepIsValid) {
                    $(validatorOptions.errorLabelContainer).html('');
                }
                return stepIsValid;
            }
        });
    }

    blueimp.Gallery($('.js-carousel a'), {
        container: '#blueimp-gallery-carousel',
        carousel: true,
        startSlideshow: false
    });
});
