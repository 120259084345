$(function () {
    var redirectUrl;
    var journal;
    var postPrefix;
    var canReload = true;

    $('a[data-target="#dokobit-journal"]').on('click', function (event) {
        event.preventDefault();

        var link = $(this);
        var modal = $('#dokobit-journal');
        var title = modal.find('.modal-title');
        var iframe = modal.find('iframe');
        postPrefix = modal.attr('data-prefix');
        var postRoute = modal.attr('data-route');
        journal = link.attr('data-journal'); // id
        redirectUrl = modal.attr('data-redirect');

        (new Promise(function (resolve, reject) {
            $.post(postPrefix + "/" + journal + "/" + postRoute).done(function (data) {
                return resolve(data);
            });
        })).then(function (data) {
            // return new Promise(function (resolve, reject) {
            if (data.sign === undefined) {
                iframe.attr('src', link.attr('data-href'));
                return;
            }

            link.attr('data-href', data.sign);
            iframe.attr('src', data.sign);
        }).then(function () {
            title.text(link.attr('data-modal-title'));
            iframe.attr('data-journal', journal);
            Isign.PostMessage.init();
        });
    });

    $('#dokobit-test').click(function (event) {
        event.preventDefault();
    });

    Isign.onSignSuccess = function () {
        // var journal = $('#isign-gateway').attr('data-journal'); // id
        // (new Promise(function (resolve) {
        //     $.post("journal" + "/" + journal + "/" + "save-signed-information").done(function (data) {
        //         return resolve(data);
        //     });
        // })).then(function () {
        //     window.location.reload();
        // });

        setTimeout(function () {
            if (-1 !== journal.indexOf(',')) {
                canReload = false;
                $.post(postPrefix + "/" + journal + "/save-signed-information-batch").done(function () {
                    canReload = true;
                });
            }

            setTimeout(reloadDelay, 1000);
        }, 200);
    };

    function reloadDelay() {
        if (canReload) {
            // prevent repeated activation if redirect takes >1000ms
            canReload = false;

            if (undefined == redirectUrl) {
                window.location.reload();
            } else {
                window.location.href = redirectUrl;
            }
        }

        setTimeout(reloadDelay, 1000);
    }

    Isign.onSignError = function (errors) {
        console.error('Unable to sign document!');
    };

    Isign.onSequentialSigningComplete = function () {
        console.error('All documents were successfully signed!');
    };
});